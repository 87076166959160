



















import { Component, Prop, Vue } from 'vue-property-decorator';
import Note from '@/models/Note';
import { getModule } from 'vuex-module-decorators';
import Notes from '@/store/modules/notes-module';

const notesState = getModule(Notes);

@Component({})
export default class ThumbNote extends Vue {
  @Prop() private note!: Note;

  get createdDate() {
    return this.note.createdAt.toLocaleString();
  }

  get body(): string {
    return this.note.body.slice(0, 15) + '...';
  }

  isInEdit() {
    return this.note.id == notesState.inEditNote.id;
  }

  remove() {
    notesState.deleteNote(this.note);
  }

  edit() {
    notesState.updateEditor(this.note);
  }
}
