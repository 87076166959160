

















import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Notes from '@/store/modules/notes-module';
import PrismEditor from 'vue-prism-editor';
import AsciiDocPreview from '@/components/AsciiDocPreview.vue';

const notesState = getModule(Notes);

@Component({
  components: {
    PrismEditor,
    AsciiDocPreview,
  },
})
export default class AddNote extends Vue {
  get note() {
    return notesState.inEditNote;
  }

  get adocTheme() {
    return notesState.adocTheme;
  }

  get editor(): Element {
    return this.$el.querySelector('.editor')!;
  }

  get preview(): Element {
    return this.$el.querySelector('.preview')!;
  }

  syncScroll(e: Event) {
    const source = e.srcElement as Element;
    console.log(source.classList)
    const scrollPct = Number((source.scrollTop / source.scrollHeight).toFixed(2));
    if (source.classList.contains("editor")) {
      this.preview.scrollTop = this.preview.scrollHeight * scrollPct;
    } else {
      this.editor.scrollTop = this.editor.scrollHeight * scrollPct;
    }
  }
}
