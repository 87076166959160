




import { Component, Prop, Vue } from 'vue-property-decorator';
import asciidoctor from 'asciidoctor';

const asciiConverter = asciidoctor();

@Component({})
export default class AsciiDocPreview extends Vue {
  @Prop() private body!: string;
  @Prop() private theme!: string;

  get renderedBody() {
    const options = {
      attributes: { icons: 'font', showtitle: true },
    };
    return asciiConverter.convert(this.body!, options).toString();
  }


}
