






















import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Notes from '@/store/modules/notes-module';
import ThumbNote from '../components/ThumbNote.vue';
import AddNote from '../components/AddNote.vue';
import UserState from '../store/modules/user-module';

@Component({
  components: { ThumbNote, AddNote },
})
export default class Home extends Vue {
  private notesState: Notes = getModule(Notes);
  private userState: UserState = getModule(UserState);
}
