









































import { Component, Vue } from 'vue-property-decorator';
import googleService from '@/google-service.ts';
import { getModule } from 'vuex-module-decorators';
import UserState from '@/store/modules/user-module';
import Notes from './store/modules/notes-module';

const userState = getModule(UserState);
const notesState = getModule(Notes);

@Component({})
export default class App extends Vue {

  private adocTheme = 'asciidoctor';

  get isSignedIn(): boolean {
    return userState.isSignedIn;
  }

  get themeOptions() {
    return [
      'asciidoctor',
      'adoc-foundation',
      'gazette',
      'ubuntu',
      'notebook',
    ];


  }

  changeTheme() {
    notesState.changeAdocTheme(this.adocTheme);
  }

  resetEditor() {
    notesState.resetEditor();
  }

  saveNote() {
    if (!this.isSignedIn) {
      this.signIn().then(() => notesState.saveNote(notesState.inEditNote));
    }
    notesState.saveNote(notesState.inEditNote);
  }

  signIn(): Promise<string> {
    return googleService.signIn();
  }

  signOut() {
    googleService.signOut();
  }
}
